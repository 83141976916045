import request from '@/utils/request'

// 查询提现管理列表
export function listProblemExtract(query) {
  return request({
    url: '/work/ProblemExtract/list',
    method: 'get',
    params: query
  })
}

// 查询提现管理详细
export function getProblemExtract(id) {
  return request({
    url: '/work/ProblemExtract/' + id,
    method: 'get'
  })
}

// 新增提现管理
export function addProblemExtract(data) {
  return request({
    url: '/work/ProblemExtract',
    method: 'post',
    data: data
  })
}

// 修改提现管理
export function updateProblemExtract(data) {
  return request({
    url: '/work/ProblemExtract/update',
    method: 'post',
    data: data
  })
}

// 删除提现管理
export function delProblemExtract(id) {
  return request({
    url: '/work/ProblemExtract/remove/' + id,
    method: 'get'
  })
}

// 审核
export function applyCheck(data) {
  return request({
    url: '/work/ProblemExtract/applyCheck',
    method: 'post',
    data: data
  })
}

// 导出提现管理
export function exportProblemExtract(query) {
  return request({
    url: '/work/ProblemExtract/export',
    method: 'get',
    params: query
  })
}